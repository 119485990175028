<template>
  <v-container v-if="!_.isEmpty(projectEditable)">
    <!-- Banner header -->
    <Banner
      :title="`Proyecto: ${projectEditable.id} ${projectEditable.migration_id ? `(ID de migración: ${projectEditable.migration_id})` : ''}`"
    >
      <template v-if="projectEditable.status !== 'CANCELLED'" v-slot:actions>
        <BtnRestructure
          v-if="
            permissionsBannerActions.restructure.includes(user.role) &&
            projectEditable.status === 'ACTIVE' &&
            projectEditable.sub_status &&
            projectEditable.sub_status.name === 'RECOVERY' &&
            projectEditable.sub_status.restructured_projects &&
            projectEditable.sub_status.restructured_projects.length < 2
          "
          :projectId="projectEditable.id"
        />
        <BtnPenalize
          v-if="
            permissionsBannerActions.penalize.includes(user.role) &&
            projectEditable.status === 'ACTIVE' &&
            projectEditable.sub_status &&
            projectEditable.sub_status.name === 'RECOVERY'
          "
          :projectId="projectEditable.id"
        />
        <v-btn
          v-if="
            permissionsBannerActions.changeToInvestable.includes(user.role) &&
            projectEditable.status === 'APPROVED' &&
            !projectEditable.to_bag
          "
          small
          @click="changeToInvestable(projectEditable.id)"
        >
          Pasar a invertible
        </v-btn>
        <v-btn
          v-if="permissionsBannerActions.toggleToBag.includes(user.role) && projectEditable.status === 'APPROVED'"
          small
          @click="toggleToBag(projectEditable.id)"
        >
          <span v-if="!projectEditable.to_bag">Pasar a proyecto de bolsa</span>
          <span v-else>Pasar a proyecto independiente</span>
        </v-btn>
        <BtnCondonedProject
          v-if="permissionsBannerActions.changeToCondoned.includes(user.role) && projectEditable.status === 'ACTIVE'"
          :project_data="projectEditable"
        />
        <BtnDefendantProject
          v-if="permissionsBannerActions.changeToDefendant.includes(user.role) && projectEditable.status === 'ACTIVE'"
          :project_data="projectEditable"
        />
        <BtnInsertCheque
          v-if="permissionsBannerActions.uploadCheque.includes(user.role) && projectEditable.status === 'SENT'"
          :project_data="projectEditable"
        />
        <BtnAddInvestment
          v-if="
            permissionsBannerActions.investProject.includes(user.role) && projectEditable.status === 'INVESTABLE' && !projectEditable.to_bag
          "
          :project_data="projectEditable"
        />
        <v-divider vertical inset class="mx-4" />
        <v-btn
          v-if="permissionsBannerActions.visibility.includes(user.role) && projectEditable.status === 'INVESTABLE'"
          small
          @click="toggleVisibility(projectEditable.id)"
        >
          <v-icon v-if="projectEditable.visible" small>visibility</v-icon>
          <v-icon v-else small>visibility_off</v-icon>
        </v-btn>
        <BtnReturnProjectToApproved
          v-if="
            (!projectEditable.investments || !projectEditable.investments.length) &&
            projectEditable.status !== 'APPROVED' &&
            !projectEditable.to_bag
          "
          :project_id="projectEditable.id"
        />
        <BtnCancelProject
          v-if="permissionsBannerActions.cancel.includes(user.role) && availableToCancel"
          :project_data="{ id: projectEditable.id }"
        />
      </template>
    </Banner>

    <!-- Information about request -->
    <div class="px-4 px-md-6">
      <p class="mt-4"><b>Estado:</b> {{ projectEditable.status }}</p>
      <p v-if="projectEditable.tir" class="mt-4"><b>TIR:</b> {{ projectEditable.tir }}</p>
      <p v-if="projectEditable.to_bag" class="mt-4">
        <b>Proyecto de bolsa:</b>
        <router-link :to="`/bolsas/${projectEditable.bag_id}`">
          {{ projectEditable.bag_id }}
        </router-link>
      </p>
      <div v-if="projectEditable.status === 'CANCELLED'" class="mt-4 red--text">
        <p class="mb-0"><b>Motivo:</b> {{ projectEditable.cancelled_reason }}</p>
        <p><b>Comentario:</b> {{ projectEditable.cancelled_comment }}</p>
      </div>
      <div v-if="projectEditable.status === 'CONDONED'" class="mt-4">
        <p class="mb-0"><b>Fecha de condonado:</b> {{ formatDateL(projectEditable.condoned_at) }}</p>
        <p class="mb-0"><b>Motivo:</b> {{ projectEditable.condoned_reason }}</p>
        <p><b>Comentario:</b> {{ projectEditable.condoned_comment }}</p>
      </div>
      <div v-if="projectEditable.sub_status" class="d-flex">
        <p class="mr-2"><b>Sub-estado:</b> {{ projectEditable.sub_status.name }}</p>
        <v-btn v-if="projectEditable.sub_status.name === 'PENALIZED'" outlined x-small @click="dialogSubstatusPenalized = true">
          <v-icon x-small>add</v-icon> info
        </v-btn>
      </div>
      <div v-if="projectEditable.sub_status && projectEditable.sub_status.restructured_projects" class="d-flex">
        <p class="mr-2"><b>Este proyecto ha sido previamente reestructurado:</b></p>
        <v-btn outlined x-small @click="$refs.recoveryInfo.openDialog()"><v-icon x-small>add</v-icon> info</v-btn>
      </div>
      <div class="secondary pa-4">
        <p v-if="projectEditable.created_at"><b>Fecha de creación:</b> {{ formatDateL(projectEditable.created_at) }}</p>
        <p v-if="projectEditable.investable_at"><b>Fecha investable:</b> {{ formatDateL(projectEditable.investable_at) }}</p>
        <p v-if="projectEditable.funded_at"><b>Fecha de inversión al 100%:</b> {{ formatDateL(projectEditable.funded_at) }}</p>
        <p v-if="projectEditable.sent_at"><b>Fecha enviado:</b> {{ formatDateL(projectEditable.sent_at) }}</p>
        <p v-if="projectEditable.active_at"><b>Fecha de activación:</b> {{ formatDateL(projectEditable.active_at) }}</p>
        <p v-if="projectEditable.paid_at"><b>Fecha de finalización:</b> {{ formatDateL(projectEditable.paid_at) }}</p>
        <p v-if="projectEditable.cancelled_at"><b>Fecha de cancelación:</b> {{ formatDateL(projectEditable.cancelled_at) }}</p>
        <p v-if="projectEditable.sub_status"><b>Fecha de sub-estado:</b> {{ formatDateL(projectEditable.sub_status.date) }}</p>
        <router-link
          v-if="permissionsCardsActions.creditTransfer.showDetails.includes(user.role) && projectEditable.money_transfer_id"
          :to="`/envio/${projectEditable.money_transfer_id}`"
        >
          Ficha del envío
        </router-link>
      </div>

      <v-row v-if="projectEditable.cheque" class="my-5">
        <v-col cols="12" md="6" lg="4">
          <ChequeInfo :project_data="projectEditable" />
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card class="secondary" max-width="400" rounded="lg">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Concepto</th>
                    <th class="text-left">Importe</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Depósito inversor ML</td>
                    <td>{{ currency(projectEditable.amount, { fromCents: true }) }}</td>
                  </tr>
                  <tr>
                    <td>Comisión Microwd ML</td>
                    <td>{{ currency(projectEditable.amount_commission, { fromCents: true }) }}</td>
                  </tr>
                  <tr>
                    <td>Importe financiado</td>
                    <td>{{ currency(projectEditable.amount_credited, { fromCents: true }) }}</td>
                  </tr>
                  <tr>
                    <td>Comisión apertura</td>
                    <td>{{ currency(projectEditable.amount_credited - projectEditable.cheque.amount, { fromCents: true }) }}</td>
                  </tr>
                  <tr>
                    <td>Importe transferido</td>
                    <td>{{ currency(projectEditable.cheque.amount, { fromCents: true }) }}</td>
                  </tr>
                  <tr>
                    <td>Importe recuperado</td>
                    <td>{{ currency(projectEditable.amount_repaid, { fromCents: true }) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>

      <div v-if="projectEditable.financial_product" class="mt-7">
        <v-card class="px-5 pb-5 secondary" max-width="400" rounded="lg">
          <v-card-title class="d-flex justify-center">
            <p class="mb-0">Producto financiero</p>
          </v-card-title>
          <v-card-text>
            <FinancialProduct :product="projectEditable.financial_product" />
          </v-card-text>
        </v-card>
      </div>
    </div>

    <!-- Form profile data -->
    <v-card class="my-8 pa-5">
      <v-card-text class="pa-5">
        <v-form ref="formEditProject" v-model="formEditProjectValid">
          <div v-if="request && borrower && borrower.borrower" class="secondary pa-5 mb-5">
            <v-row class="black--text">
              <v-col cols="12" md="6">
                <p v-if="borrower.migration_id" class="mb-1"><b>ID migración prestataria:</b> {{ borrower.migration_id }}</p>
                <p class="mb-1">
                  <b>ID de la prestataria: </b>
                  <router-link :to="`/prestatarias/${projectEditable.borrower_id}`">{{ projectEditable.borrower_id }}</router-link>
                </p>
                <p class="mb-1">
                  <b>ID de la solicitud Solicitud: </b>
                  <router-link :to="`/solicitudes/${projectEditable.request_id}`">{{ projectEditable.request_id }}</router-link>
                </p>
                <p class="mb-1"><b>Sueño de la mujer:</b> {{ request.dream }}</p>
                <p class="mb-1"><b>Se define a sí misma como:</b> {{ request.definition_yourself }}</p>
                <p class="mb-0"><b>Número de créditos de la mujer:</b> {{ borrower.borrower.num_credits }}</p>
              </v-col>
              <v-col cols="12" md="6">
                <v-img max-width="300" :src="image_business" />
              </v-col>
            </v-row>
          </div>
          <BorrowerAutocomplete
            :borrowerId="projectEditable.borrower_id"
            :editable="false"
            @selected="projectEditable.borrower_id = $event"
          />
          <v-select
            v-model="projectEditable.sector"
            :items="sectors"
            item-text="name"
            item-value="id"
            label="Sector"
            :rules="[(v) => !!v || 'Campo obligatorio']"
            :readonly="!editable || !['SUPERADMIN', 'ADMIN'].includes(user.role)"
          />
          <v-select v-model="projectEditable.partner_id" :items="partners" item-text="name" item-value="id" label="Partner" readonly />
          <OfficeAutocomplete :officeId="projectEditable.office_id" :editable="false" @selected="projectEditable.office_id = $event" />
          <v-autocomplete
            v-model="projectEditable.city"
            :items="cities"
            item-text="name"
            item-value="id"
            label="Ciudad"
            :rules="formRules.textRules"
            :readonly="!editable || !['SUPERADMIN', 'ADMIN'].includes(user.role)"
          />
          <v-text-field
            v-model="projectEditable.zone"
            label="Zona"
            :readonly="!editable || !['SUPERADMIN', 'ADMIN'].includes(user.role)"
            :rules="formRules.textRules"
          />
          <div class="d-flex align-center">
            <TechnicianAutocomplete
              ref="technicianAutocomplete"
              :technicianId="projectEditable.technician_id"
              :editable="false"
              style="width: 100%"
            />
            <BtnChangeTechnician
              :project_data="projectEditable"
              @technicianChanged="formatFields(), $refs.technicianAutocomplete.formatField()"
            />
          </div>
          <v-autocomplete
            v-model="projectEditable.currency"
            :items="currencies"
            item-text="name"
            item-value="id"
            label="Moneda"
            :rules="[(v) => !!v || 'Campo obligatorio']"
            readonly
          />
          <div class="d-flex align-center">
            <v-text-field
              :value="currency(projectEditable.amount, { fromCents: true })"
              type="number"
              label="Cantidad solicitada"
              readonly
            />
            <BtnChangeAmountRequested
              v-if="projectEditable.status === 'APPROVED' && permissionsCardsActions.changeAmountRequested.includes(user.role)"
              :project_data="{ id: projectEditable.id, amountRequested: projectEditable.amount_requested }"
            />
          </div>
          <v-text-field :value="currency(projectEditable.amount, { fromCents: true })" type="number" label="Cantidad a invertir" readonly />
          <v-text-field
            :value="currency(projectEditable.amount_invested, { fromCents: true })"
            type="number"
            label="Cantidad invertida"
            readonly
          />
          <v-text-field
            v-model="projectEditable.address.name"
            label="Dirección"
            :rules="formRules.textRules"
            :readonly="!editable || !['SUPERADMIN', 'ADMIN', 'SUPERTECHNICIAN', 'TECHNICIAN'].includes(user.role)"
          />
          <v-text-field
            v-model="projectEditable.activity"
            label="Actividad"
            :rules="formRules.textRules"
            :readonly="!editable || !['SUPERADMIN', 'ADMIN'].includes(user.role)"
          />
          <v-textarea
            v-model="projectEditable.description"
            label="Descripción"
            rows="1"
            auto-grow
            :rules="formRules.textRules"
            :readonly="!editable || !['SUPERADMIN', 'ADMIN'].includes(user.role)"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-icon v-if="!editable && projectEditable.status !== 'CANCELLED'" @click="editable = true">edit</v-icon>
        <v-btn v-if="editable" text @click="(editable = false), formatFields()">Cancelar</v-btn>
        <v-btn v-if="editable" text @click="edit">Guardar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Investments table and dialogs -->
    <Investments :projectEditable="projectEditable" />

    <!-- Repayments table and dialogs -->
    <Repayments :projectEditable="projectEditable" />

    <!-- Comments table and dialogs -->
    <Comments :projectEditable="projectEditable" />

    <!-- Data table calendar payments -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>
        <span>Calendario de pagos</span>
        <v-spacer />
        <v-btn v-if="projectEditable.calendar_payments" x-small @click="recalculate()">Recalcular</v-btn>
      </v-toolbar>
      <Moratoriums
        v-if="projectEditable.status === 'ACTIVE' && projectEditable.calendar_payments && user.role === 'SUPERADMIN'"
        :project="projectEditable"
        :action="'editProjectMoratoriums'"
      />
      <CalendarPayments
        v-if="projectEditable.calendar_payments"
        :calendarPayments="projectEditable.calendar_payments"
        :financialProductType="projectEditable.financial_product.type"
      />
      <v-simple-table v-else>
        <template v-slot:default>
          <tbody>
            <tr>
              <td class="text-center text--disabled">No hay datos disponibles</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <!-- Calculated fields + Prepaid info -->
    <v-card v-if="projectEditable.calendar_payments" class="secondary pa-5">
      <v-row>
        <v-col cols="12" md="6">
          <div>
            <p class="mb-1"><b>Fecha de hoy: </b>{{ formatDateL(dateNowFormated()) }}</p>
            <p class="mb-1"><b>Fecha de cheque: </b>{{ formatDateL(projectEditable.active_at) }}</p>
            <p class="mb-1"><b>Importe de cheque: </b>{{ currency(projectEditable.amount_credited, { fromCents: true }) }}</p>
            <p class="mb-1">
              <b>Total a devolver: </b>
              {{ currency(projectEditable.amount_repaid + projectEditable.amount_repaid_remaining, { fromCents: true }) }}
            </p>
            <p class="mb-1"><b>Cuotas: </b>{{ currency(projectEditable.amount_repaid, { fromCents: true }) }}</p>
            <p class="mb-1"><b>Pendiente a devolver: </b>{{ currency(projectEditable.amount_repaid_remaining, { fromCents: true }) }}</p>
          </div>
        </v-col>
        <v-col v-if="user.role === 'SUPERADMIN'" cols="12" md="6">
          <PrepaidInfo
            v-if="
              (projectEditable.status === 'ACTIVE' || projectEditable.status === 'DEFENDANT') &&
              projectEditable.financial_product.type === 'COOPERATIVE'
            "
            :id="projectEditable.id"
            :maxDate="projectEditable.calendar_payments.payments[projectEditable.calendar_payments.payments.length - 2].due_date"
            :actionName="'getAmountPrepaidProject'"
          />
        </v-col>
      </v-row>
    </v-card>

    <!-- Dialog sub status penalized info -->
    <DialogBox v-if="projectEditable.sub_status" :model="dialogSubstatusPenalized">
      <template slot="toolbar">
        <span>Información sobre el sub-estado</span>
      </template>
      <template slot="content">
        <ul class="mb-3">
          <li><b>Cantidad:</b> {{ currency(projectEditable.sub_status.amount, { fromCents: true }) }}</li>
          <li><b>Comentario:</b> {{ projectEditable.sub_status.comment }}</li>
        </ul>
        <v-btn v-if="projectEditable.sub_status.document" small @click="getUrlAndOpen(projectEditable.sub_status.document)">
          <v-icon small>attach_file</v-icon>
          Documento
        </v-btn>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogSubstatusPenalized = false">Cerrar</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog sub status recovery info -->
    <RecoveryInfo v-if="projectEditable.sub_status" ref="recoveryInfo" :info="projectEditable.sub_status" />
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import * as currency from "currency.js";
import Banner from "@/components/elements/Banner";
import BorrowerAutocomplete from "@/components/elements/borrower/BorrowerAutocomplete";
import BtnAddInvestment from "@/components/elements/project/BtnAddInvestment";
import BtnReturnProjectToApproved from "@/components/elements/project/BtnReturnProjectToApproved";
import BtnCancelProject from "@/components/elements/project/BtnCancelProject";
import BtnChangeAmountRequested from "@/components/elements/project/BtnChangeAmountRequested";
import BtnChangeTechnician from "@/components/elements/project/BtnChangeTechnician";
import BtnCondonedProject from "@/components/elements/project/BtnCondonedProject";
import BtnDefendantProject from "@/components/elements/project/BtnDefendantProject";
import BtnInsertCheque from "@/components/elements/project/BtnInsertCheque";
import BtnRestructure from "@/components/elements/project/BtnRestructure";
import BtnPenalize from "@/components/elements/project/BtnPenalize";
import CalendarPayments from "@/components/elements/CalendarPayments";
import ChequeInfo from "@/components/elements/project/ChequeInfo";
import CommonMixin from "@/mixins/CommonMixin";
import DialogBox from "@/components/elements/DialogBox";
import FinancialProduct from "@/components/elements/products/FinancialProduct";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import Investments from "@/components/elements/project/Investments";
import listeners from "@/firebase/listeners";
import Moratoriums from "@/components/elements/project/Moratoriums";
import PrepaidInfo from "@/components/elements/project/PrepaidInfo";
import RecoveryInfo from "@/components/elements/project/RecoveryInfo";
import Repayments from "@/components/elements/project/Repayments";
import Comments from "@/components/elements/project/Comments";
import OfficeAutocomplete from "@/components/elements/office/OfficeAutocomplete";
import TechnicianAutocomplete from "@/components/elements/technician/TechnicianAutocomplete";

export default {
  name: "Project",
  components: {
    Banner,
    BorrowerAutocomplete,
    BtnAddInvestment,
    BtnReturnProjectToApproved,
    BtnCancelProject,
    BtnChangeAmountRequested,
    BtnChangeTechnician,
    BtnCondonedProject,
    BtnDefendantProject,
    BtnInsertCheque,
    BtnRestructure,
    BtnPenalize,
    CalendarPayments,
    ChequeInfo,
    DialogBox,
    FinancialProduct,
    Investments,
    Moratoriums,
    PrepaidInfo,
    Repayments,
    Comments,
    OfficeAutocomplete,
    RecoveryInfo,
    TechnicianAutocomplete
  },
  mixins: [FormRulesMixin, FormatDateMixin, CommonMixin],
  data() {
    return {
      // Banner actions permissions
      permissionsBannerActions: {
        cancel: ["SUPERADMIN"],
        investProject: ["SUPERADMIN", "ADMIN"],
        changeToInvestable: ["SUPERADMIN", "ADMIN"],
        toggleToBag: ["SUPERADMIN", "ADMIN"],
        changeToCondoned: ["SUPERADMIN", "ADMIN"],
        changeToDefendant: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"],
        restructure: ["SUPERADMIN", "ADMIN"],
        penalize: ["SUPERADMIN", "ADMIN"],
        uploadCheque: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN", "TECHNICIAN"],
        visibility: ["SUPERADMIN", "ADMIN"]
      },

      // Calendar payments datatable
      tableCalendarPaymentsHeaders: [
        { text: "Fecha", value: "due_date" },
        { text: "Real", value: "real" },
        { text: "Futuro", value: "future" },
        { text: "Teórico", value: "theoretical" },
        { text: "Estado", value: "status" }
      ],

      // Cards actions permissions
      permissionsCardsActions: {
        creditTransfer: {
          showDetails: ["SUPERADMIN", "ADMIN"]
        },
        profileImage: {
          edit: ["SUPERADMIN", "ADMIN"]
        },
        changeAmountRequested: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"]
      },

      // Form edit project
      editable: false,
      formEditProjectValid: false,
      projectEditable: {},
      image_business: "",

      // Dialogs
      dialogSubstatusPenalized: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      project: (state) => state.projects.project,
      request: (state) => state.requests.request,
      borrower: (state) => state.borrowers.borrower,
      sectors: (state) => state.sectors.sectors,
      currencies: (state) => state.currencies.currencies,
      partners: (state) => state.partners.partners,
      cities: (state) => state.locations.cities
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    },
    availableToCancel() {
      const statusAvaiblables = ["APPROVED", "INVESTABLE", "FUNDED", "SENT"];
      return statusAvaiblables.includes(this.projectEditable.status);
    }
  },
  watch: {
    project() {
      this.formatFields();
    },
    editable() {
      this.$store.commit("SET_EDITING", this.editable);
    }
  },
  async created() {
    this.$store.commit("SET_LOADING", true);
    await listeners.project(this.$route.params.id);
    // Push back if id does not exist
    if (this._.isEmpty(this.project) || this.project.id !== this.$route.params.id) {
      this.$router.push("/proyectos");
      this.$store.commit("SET_LOADING", false);
      return;
    }
    this.formatFields();
    await listeners.request(this.projectEditable.request_id);
    await listeners.borrower(this.projectEditable.borrower_id);
    await this.$store.dispatch("partners/getPartners");
    await this.$store.dispatch("locations/getCities", { filter: { country: this.project.country } });
    await this.$store.dispatch("sectors/getSectors");
    await this.$store.dispatch("currencies/getCurrencies");

    if (this.request?.image_business) this.image_business = await this.getFileFromStorage(this.request.image_business);
    this.$store.commit("SET_LOADING", false);
  },
  methods: {
    ...mapActions({
      changeToInvestable: "projects/changeToInvestable",
      toggleToBag: "projects/toggleToBag",
      toggleVisibility: "projects/toggleVisibility"
    }),

    // Format fields editables
    formatFields() {
      this.projectEditable = this._.cloneDeep(this.project);
      if (this.projectEditable.investments) this.projectEditable.investments = this.objectToArray(this.projectEditable.investments);
      if (this.projectEditable.repayments) this.projectEditable.repayments = this.objectToArray(this.projectEditable.repayments);
    },

    // Edit project
    async edit() {
      if (this.$refs.formEditProject.validate()) {
        let projectEditableFormattedData = {
          id: this.projectEditable.id,
          technician_id: this.projectEditable.technician_id,
          sector: this.projectEditable.sector,
          activity: this.projectEditable.activity,
          description: this.projectEditable.description,
          address: this.projectEditable.address,
          city: this.projectEditable.city,
          zone: this.projectEditable.zone
        };

        await this.$store.dispatch("projects/editProject", projectEditableFormattedData);

        this.formatFields();
        this.editable = false;
      }
    },

    // Recalculate calendar payments
    async recalculate() {
      await this.$store.dispatch("projects/recalculateCalendarPayments", this.projectEditable.id);
    }
  }
};
</script>
