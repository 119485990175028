<template>
  <div>
    <!-- Data table repayments -->
    <v-card class="my-8">
      <v-toolbar color="primary" dense>
        <span>Cobros</span>
        <v-spacer />
        <v-btn
          v-if="
            repaymentPermissions.add.includes(user.role) && (projectEditable.status === 'ACTIVE' || projectEditable.status === 'DEFENDANT')
          "
          rounded
          small
          @click="addRepaymentDialog = true"
        >
          Añadir cobro
        </v-btn>
      </v-toolbar>

      <DataTable
        :headers="tableRepaymentsHeaders"
        :items="projectEditable.repayments ? projectEditable.repayments : []"
        :sortBy="'paid_at'"
        :sortDesc="false"
        :hideDefaultFooter="false"
      >
        <!-- <template v-slot:[`item.breakdown`]="{ item }">
          <a @click="(selectedRepayment = item), (repaymentBreakDownDialog = true)"><u>Ver desglose</u></a>
        </template> -->
        <template v-slot:[`item.amount`]="{ item }">
          {{ currency(item.amount, { fromCents: true }) }}
        </template>
        <template v-slot:[`item.paid_at`]="{ item }">
          {{ formatDateL(item.paid_at) }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
        <template v-slot:[`item.consolidated`]="{ item }">
          {{ item.consolidated ? "Sí" : "No" }}
        </template>
        <template v-if="projectEditable.status !== 'CANCELLED'" v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="repaymentPermissions.showImage.includes(user.role)"
            @click="getUrlAndOpen(item.receipt_photo)"
            title="Ver foto del cobro"
          >
            image
          </v-icon>
          <v-icon v-if="repaymentPermissions.edit.includes(user.role)" @click="handleBtnOpenEditRepaymentDialog(item)">edit</v-icon>
          <v-icon
            v-if="repaymentPermissions.delete.includes(user.role) && !item.consolidated && item.status === 'CREATED'"
            color="red"
            @click="(selectedRepayment = item), (deleteRepaymentDialog = true)"
          >
            delete
          </v-icon>
          <v-btn
            v-if="
              repaymentPermissions.consolidate.includes(user.role) &&
              !item.consolidated &&
              environment !== 'production' &&
              item.status === 'CREATED'
            "
            color="info"
            x-small
            class="ml-5"
            @click="(selectedRepayment = item), (consolidateRepaymentDialog = true)"
          >
            consolidar
          </v-btn>
          <v-btn
            v-if="
              repaymentPermissions.consolidate.includes(user.role) &&
              !item.consolidated &&
              environment !== 'production' &&
              item.status === 'REVIEWED'
            "
            color="info"
            x-small
            class="ml-5"
            @click="(selectedRepayment = item), (consolidateRepaymentDialog = true)"
          >
            desconsolidar
          </v-btn>
        </template>
        <template v-if="projectEditable.repayments" slot="body.append">
          <tr>
            <th>TOTAL: {{ currency(projectEditable.amount_repaid, { fromCents: true }) }}</th>
          </tr>
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog add repayment -->
    <DialogBox v-if="projectEditable.status === 'ACTIVE' || projectEditable.status === 'DEFENDANT'" :model="addRepaymentDialog">
      <template slot="toolbar">
        <span>Añadir cobro</span>
      </template>
      <template slot="content">
        <v-form ref="formAddRepayment" v-model="formAddRepaymentValid">
          <v-alert v-if="warningRepaymentGreaterThanFee()" dense border="left" outlined type="warning" icon="warning">
            La cantidad introducida es <strong>mayor al 200%</strong> de la cuota
          </v-alert>
          <v-text-field v-model.number="newRepayment.amount" type="number" label="Cantidad" :rules="formRules.numberRules" />
          <DatePicker
            label="Fecha del cobro"
            :parent_model="newRepayment.paid_at"
            :min="$store.state.user.user.role === 'SUPERADMIN' ? '' : date30DaysBeforeToday()"
            :max="$store.state.user.user.role === 'SUPERADMIN' ? '' : dateNowFormated()"
            :required="true"
            @change="setNewRepaymentDate"
          />
          <v-text-field v-model="newRepayment.receiptNumber" label="Número de recibo" :rules="formRules.textRules" />
          <v-file-input
            v-model="newRepayment.receiptImage"
            :accept="['.jpeg', '.png', '.jpg']"
            truncate-length="100"
            :clearable="false"
            prepend-icon="image"
            label="Imagen del cobro"
            :rules="formRules.imageRequiredRules"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('addRepaymentDialog', 'formAddRepayment')">Cerrar</v-btn>
        <v-btn text @click="addRepayment()">Añadir</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog edit repayment -->
    <DialogBox :model="editRepaymentDialog">
      <template slot="toolbar">
        <span>Editar cobro</span>
      </template>
      <template slot="content">
        <v-form ref="formEditRepayment" v-model="formEditRepaymentValid">
          <DatePicker
            label="Fecha del cobro"
            :parent_model="editRepaymentValues.paid_at"
            :min="date30DaysBeforeToday()"
            :max="dateNowFormated()"
            :required="true"
            @change="setEditRepaymentDate"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('editRepaymentDialog', 'formEditRepayment')">Cerrar</v-btn>
        <v-btn text @click="editRepayment()">Editar</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog consolidate repayment -->
    <DialogBox v-if="selectedRepayment" :model="consolidateRepaymentDialog" :color="'info'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de {{ selectedRepayment.status === "CREATED" ? "consolidar" : "desconsolidar" }} este cobro?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="consolidateRepaymentDialog = false">Cerrar</v-btn>
        <v-btn
          v-if="selectedRepayment.status === 'CREATED'"
          text
          @click="(consolidateRepaymentDialog = false), changeRepaymentToReviewed()"
        >
          consolidar
        </v-btn>
        <v-btn v-else text @click="(consolidateRepaymentDialog = false), returnRepaymentToCreatedStatus()">
          {{ selectedRepayment.status === "CREATED" ? "consolidar" : "desconsolidar" }}
        </v-btn>
      </template>
    </DialogBox>

    <!-- Dialog delete repayment -->
    <DialogBox :model="deleteRepaymentDialog" :color="'error'" isdark>
      <template slot="toolbar">
        <span>¿Estás seguro de eliminar este cobro?</span>
      </template>
      <template slot="actions">
        <v-btn text @click="deleteRepaymentDialog = false">Cerrar</v-btn>
        <v-btn text @click="(deleteRepaymentDialog = false), deleteProjectRepayment()">eliminar</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog repayment breakdown -->
    <DialogBox v-if="false" :model="repaymentBreakDownDialog" :max_width="'450px'">
      <template slot="toolbar">
        <span>Desglose del cobro</span>
      </template>
      <template slot="content">
        <SimpleTableFields text="Comision contraparte" :value="selectedRepayment.payment_breakdown.partner_commission" :divider="false" />
        <SimpleTableFields text="IVA mora" :value="selectedRepayment.payment_breakdown.arrear_vat" :divider="false" />
        <SimpleTableFields text="Mora" :value="selectedRepayment.payment_breakdown.arrear" :divider="false" />
        <SimpleTableFields text="IVA deuda" :value="selectedRepayment.payment_breakdown.debt_vat" :divider="false" />
        <SimpleTableFields text="Intereses deuda" :value="selectedRepayment.payment_breakdown.debt_interest" :divider="false" />
        <SimpleTableFields text="Capital deuda" :value="selectedRepayment.payment_breakdown.debt_capital" :divider="false" />
        <SimpleTableFields text="IVA cuota corriente" :value="selectedRepayment.payment_breakdown.current_vat" :divider="false" />
        <SimpleTableFields
          text="Intereses cuota corriente"
          :value="selectedRepayment.payment_breakdown.current_interest"
          :divider="false"
        />
        <SimpleTableFields text="Capital cuota corriente" :value="selectedRepayment.payment_breakdown.current_capital" :divider="false" />
        <SimpleTableFields text="Cuota corriente" :value="selectedRepayment.payment_breakdown.current_fee" :divider="false" />
        <SimpleTableFields text="TOTAL PAGADO" :value="selectedRepayment.payment_breakdown.current_total" :divider="false" />
      </template>
      <template slot="actions">
        <v-btn text @click="repaymentBreakDownDialog = false">Cerrar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import DatePicker from "@/components/elements/DatePicker";
import SimpleTableFields from "@/components/elements/SimpleTableFields.vue";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CommonMixin from "@/mixins/CommonMixin";
import * as currency from "currency.js";

export default {
  components: {
    DataTable,
    DialogBox,
    DatePicker,
    SimpleTableFields
  },
  mixins: [FormRulesMixin, FormatDateMixin, CommonMixin],
  props: {
    projectEditable: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      environment: process.env.NODE_ENV,

      // Repayments datatable
      tableRepaymentsHeaders: [
        // { text: "", value: "breakdown", sortable: false },
        { text: "Cantidad", value: "amount", sortable: false },
        { text: "Nº Recibo", value: "receipt_number", sortable: false },
        { text: "Fecha del cobro", value: "paid_at", sortable: false },
        { text: "Consolidado", value: "consolidated", sortable: false },
        { text: "Fecha de creación", value: "created_at", sortable: false },
        { text: "", value: "actions", align: "center", width: "18%" }
      ],

      // Repayment permissions
      repaymentPermissions: {
        add: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"],
        edit: ["SUPERADMIN"],
        delete: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"],
        showImage: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"],
        consolidate: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"]
      },

      // Form add repayment
      formAddRepaymentValid: false,
      newRepayment: {
        amount: 0,
        paid_at: "",
        receiptNumber: "",
        receiptImage: null
      },

      // Form edit repayment
      formEditRepaymentValid: false,
      editRepaymentValues: {
        paid_at: ""
      },

      // Repayment selected to edit/delete
      selectedRepayment: null,

      // Dialogs
      addRepaymentDialog: false,
      editRepaymentDialog: false,
      consolidateRepaymentDialog: false,
      deleteRepaymentDialog: false,
      repaymentBreakDownDialog: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    currency() {
      return currency;
    }
  },
  methods: {
    // Add a repayment to the project
    async addRepayment() {
      if (this.$refs.formAddRepayment.validate()) {
        const file = await this.toBase64(this.newRepayment.receiptImage);

        await this.$store.dispatch("projects/addRepaymentProject", {
          amount: this.convertToInt(this.newRepayment.amount),
          file,
          paid_at: this.formatDateToISO8601(this.newRepayment.paid_at),
          project_id: this.projectEditable.id,
          receipt_number: this.newRepayment.receiptNumber
        });

        this.closeDialog("addRepaymentDialog", "formAddRepayment");
      }
    },

    // Edit a repayment
    async editRepayment() {
      if (this.$refs.formEditRepayment.validate()) {
        await this.$store.dispatch("projects/editRepayment", {
          project_id: this.projectEditable.id,
          repayment_id: this.selectedRepayment.id,
          paid_at: this.formatDateToISO8601(this.editRepaymentValues.paid_at)
        });

        this.closeDialog("editRepaymentDialog", "formEditRepayment");
      }
    },

    async deleteProjectRepayment() {
      await this.$store.dispatch("projects/deleteProjectRepayment", this.selectedRepayment.id);
    },

    async changeRepaymentToReviewed() {
      await this.$store.dispatch("projects/changeRepaymentToReviewed", this.selectedRepayment.id);
    },

    async returnRepaymentToCreatedStatus() {
      await this.$store.dispatch("projects/returnRepaymentToCreatedStatus", this.selectedRepayment.id);
    },

    // Set repaymentDate with the paid_at selected in datepicker
    setNewRepaymentDate(value) {
      this.newRepayment.paid_at = value;
    },
    setEditRepaymentDate(value) {
      this.editRepaymentValues.paid_at = value;
    },

    handleBtnOpenEditRepaymentDialog(item) {
      this.editRepaymentDialog = true;
      this.selectedRepayment = item;
      this.editRepaymentValues.paid_at = item.paid_at;
    },

    // Return if repayment amount in text-field is greater than the fee
    warningRepaymentGreaterThanFee() {
      if (this.projectEditable.financial_product.type === "COOPERATIVE") {
        const currentPaymentNumber = this.projectEditable.calendar_payments?.current_payment_number;
        const fee = this.projectEditable.calendar_payments?.payments[currentPaymentNumber]?.future.current_total;
        return this.newRepayment.amount > (200 / 100) * fee;
      } else return false;
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.createProjectComment = this.$options.data().createProjectComment;
      this.newRepayment = this.$options.data().newRepayment;
      this.editRepaymentValues = this.$options.data().editRepaymentValues;
    }
  }
};
</script>
